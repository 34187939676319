const authenticatedAppActions = {
  AUTHENTICATED_APP_START_SPINNER: 'AUTHENTICATED_APP_START_SPINNER',
  AUTHENTICATED_APP_STOP_SPINNER: 'AUTHENTICATED_APP_STOP_SPINNER',
  AUTHENTICATED_APP_GET_PATIENT_DETAILS_SUCCESS: 'AUTHENTICATED_APP_GET_PATIENT_DETAILS_SUCCESS',
  AUTHENTICATED_APP_GET_PATIENT_DETAILS_FAILURE: 'AUTHENTICATED_APP_GET_PATIENT_DETAILS_FAILURE',
  AUTHENTICATED_APP_GET_DOCTOR_COMPANY_MODULE_ACCESS_SUCCESS:
    'AUTHENTICATED_APP_GET_DOCTOR_COMPANY_MODULE_ACCESS_SUCCESS',
  AUTHENTICATED_APP_GET_DOCTOR_COMPANY_MODULE_ACCESS_FAILURE:
    'AUTHENTICATED_APP_GET_DOCTOR_COMPANY_MODULE_ACCESS_FAILURE',
  AUTHENTICATED_APP_GET_DOCTOR_COMPANY_LOGO_SUCCESS:
    'AUTHENTICATED_APP_GET_DOCTOR_COMPANY_LOGO_SUCCESS',
  AUTHENTICATED_APP_GET_DOCTOR_COMPANY_LOGO_FAILURE:
    'AUTHENTICATED_APP_GET_DOCTOR_COMPANY_LOGO_FAILURE',
  AUTHENTICATED_APP_GET_COMPANY_ACCESS_PREFERENCES_SUCCESS:
    'AUTHENTICATED_APP_GET_COMPANY_ACCESS_PREFERENCES_SUCCESS',
  AUTHENTICATED_APP_GET_COMPANY_ACCESS_PREFERENCES_FAILURE:
    'AUTHENTICATED_APP_GET_COMPANY_ACCESS_PREFERENCES_FAILURE',
  AUTHENTICATED_APP_GET_COMPANY_APPLICATION_CONFIGURATION_SUCCESS:
    'AUTHENTICATED_APP_GET_COMPANY_APPLICATION_CONFIGURATION_SUCCESS',
  AUTHENTICATED_APP_GET_COMPANY_APPLICATION_CONFIGURATION_FAILURE:
    'AUTHENTICATED_APP_GET_COMPANY_APPLICATION_CONFIGURATION_FAILURE',
  AUTHENTICATED_APP_TRIGGER_RELOAD_DASHBOARD: 'AUTHENTICATED_APP_TRIGGER_RELOAD_DASHBOARD',
  AUTHENTICATED_APP_GET_WEAVY_USER_TOKEN_SUCCESS: 'AUTHENTICATED_APP_GET_WEAVY_USER_TOKEN_SUCCESS',
  AUTHENTICATED_APP_GET_WEAVY_USER_TOKEN_FAILURE: 'AUTHENTICATED_APP_GET_WEAVY_USER_TOKEN_FAILURE',
  AUTHENTICATED_APP_GET_WEAVY_CONFIG_FAILURE: 'AUTHENTICATED_APP_GET_WEAVY_CONFIG_FAILURE',
  AUTHENTICATED_APP_GET_WEAVY_CONFIG_SUCCESS: 'AUTHENTICATED_APP_GET_WEAVY_CONFIG_SUCCESS',
};

export default authenticatedAppActions;
