import * as types from './constants';
import initialState from './initial.state';

export default function hospitalizationHxReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_HOSPITALIZATIONHX_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_HOSPITALIZATIONHX_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmitted: false,
        data: action.response.PatientHospitalizationList,
      };
    case types.COMPONENTS_HOSPITALIZATIONHX_ADD_ROW:
      return {
        ...state,
        loading: false,
        data: action.newData,
      };
    case types.COMPONENTS_SEARCH_HOSP_DIAG_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        diagnosisList: action.DiagList,
      };
    case types.COMPONENTS_HOSPITALIZATIONHX_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: true,
        data: action.data,
      };

    case types.COMPONENTS_HOSPITALIZATIONHX_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: false,
      };
    case types.COMPONENTS_HOSPITALIZATIONHX_DELETE_ROW:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSubmitted: false,
        showError: false,
      };
    default:
      return state;
  }
}
