import * as types from './constants';
import initialState from './initial.state';

export default function LabsMasterReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_LABS_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_LABS_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_LABS_LOAD_SUCCESS:
      return {
        ...state,
        data: action.data,
        startDate: action.startDate,
        showOrder: true,
        showResults: false,
        comments: false,
      };
    case types.COMPONENTS_LAB_DETAIL_LOADING:
      return {
        ...state,
        labDetailLoading: true,
        labDetail: null,
      };
    case types.COMPONENTS_LAB_DETAIL_SUCCESS:
      return {
        ...state,
        labDetail: action.data,
        labDetailLoading: false,
      };
    case types.COMPONENTS_LAB_DETAIL_ERROR:
      return {
        ...state,
        labDetailLoading: false,
      };
    case types.COMPONENTS_DOWNLOAD_LAB_SPINNER_START:
      return {
        ...state,
        labPdfLoading: true,
      };
    case types.COMPONENTS_DOWNLOAD_LAB_SPINNER_STOP:
      return {
        ...state,
        labPdfLoading: false,
      };
    case types.COMPONENTS_LABS_SHOW_ORDER:
      return {
        ...state,
        showOrder: true,
        showResults: false,
      };
    case types.COMPONENTS_LABS_SHOW_RESULTS:
      return {
        ...state,
        showOrder: false,
        showResults: true,
      };
    case types.COMPONENTS_LABS_VIEW_COMMENTS:
      return {
        ...state,
        comments: true,
      };
    case types.COMPONENTS_LABS_HIDE_COMMENTS:
      return {
        ...state,
        comments: false,
      };
    case types.COMPONENTS_LABS_LOAD_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
