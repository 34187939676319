import * as types from './constants';
import initialState from './initial.state';

export default function SocialHxReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_SOCIALHX_SPINNER_START:
      return {
        ...state,
        loading: true,
        isSubmitted: false,
        showError: false,
      };
    case types.COMPONENTS_SOCIALHX_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.PatientSocialHx.PatientSmokingStatusList,
        isSubmitted: false,
        showError: false,
      };
    case types.COMPONENTS_SOCIALHX_ADD_ROW:
      return {
        ...state,
        loading: false,
        data: action.newData,
      };
    case types.COMPONENTS_SOCIALHX_DELETE_ROW:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSubmitted: false,
        showError: false,
      };

    case types.COMPONENTS_SEARCH_SOCIALHX_CONST_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        constants: action.response.SmokingStatusList,
      };
    case types.COMPONENTS_SEARCH_SOCIALHX_DIAG_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        diagnosisList: action.DiagList,
      };
    case types.COMPONENTS_SOCIALHX_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: true,
        data: action.data,
      };
    case types.COMPONENTS_SOCIALHX_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: false,
      };
    default:
      return state;
  }
}
