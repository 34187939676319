import * as types from './constants';
import initialState from './initial.state';

export default function activitiesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_ACTIVITIES_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_ACTIVITIES_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_ACTIVITIES_LOAD_SUCCESS:
      return {
        ...state,
        activities: action.response.Data,
        totalCount: action.response.TotalCount,
        totalPages: action.response.TotalPages,
        loading: false,
      };
    case types.COMPONENTS_ACTIVITIES_LOAD_FAILURE:
      return {
        ...state,
        activities: [],
      };
    case types.COMPONENTS_ACTIVITIES_SEARCH_RESET:
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
}
