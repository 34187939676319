export const COMPONENTS_PATIENT_DOCUMENTS_SPINNER_START =
  'COMPONENTS_PATIENT_DOCUMENTS_SPINNER_START';
export const COMPONENTS_PATIENT_DOCUMENTS_SPINNER_STOP =
  'COMPONENTS_PATIENT_DOCUMENTS_SPINNER_STOP';

export const COMPONENTS_PATIENT_DOCUMENTS_DOWNLOAD_CCD_SUCCESS =
  'COMPONENTS_PATIENT_DOCUMENTS_DOWNLOAD_CCD_SUCCESS';
export const COMPONENTS_PATIENT_DOCUMENTS_DOWNLOAD_CCD_FAILURE =
  'COMPONENTS_PATIENT_DOCUMENTS_DOWNLOAD_CCD_FAILURE';

export const COMPONENTS_PATIENT_DOCUMENTS_RESET_STATE = 'COMPONENTS_PATIENT_DOCUMENTS_RESET_STATE';
export const COMPONENTS_PATIENT_DOCUMENTS_FORM_SUBMITTED =
  'COMPONENTS_PATIENT_DOCUMENTS_FORM_SUBMITTED';
export const COMPONENTS_PATIENT_DOCUMENTS_SET_DATES = 'COMPONENTS_PATIENT_DOCUMENTS_SET_DATES';
export const COMPONENT_PREVIEW_CCD_TOGGLE_MODAL = 'COMPONENT_PREVIEW_CCD_TOGGLE_MODAL';
