import { initialState } from './auth.state';
import * as types from './constants';

export default function authReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.STORE_SET_LOGGED_IN_USER_INFO:
      return {
        ...state,
        isLoggedIn: action.response.isLoggedIn,
        user: {
          ...action.response.user,
          patientRepresentativeId:
            Number(action.response.user.patientRepresentativeId) > 0
              ? action.response.user.patientRepresentativeId
              : undefined,
          loggedInUserId:
            Number(action.response.user.patientRepresentativeId) > 0
              ? action.response.user.patientRepresentativeId
              : action.response.user.patientId,
        },
        externalAuthGlobalInfo: undefined,
      };
    case types.STORE_LOGOUT_USER:
      return {
        ...state,
        isLoggedIn: false,
        user: undefined,
        externalAuthGlobalInfo: undefined,
      };
    case types.STORE_SET_PATIENT_EMAIL_TOKEN:
      return {
        ...state,
        emailToken: action.emailToken ? action.emailToken : '',
      };
    case types.STORE_CLEAR_PATIENT_EMAIL_TOKEN:
      return {
        ...state,
        emailToken: '',
      };
    case types.AUTH_ACCEPT_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          acceptedTermsDate: action.payload,
          acceptedTermsDateError: undefined,
        },
      };
    case types.AUTH_ACCEPT_TERMS_AND_CONDITIONS_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          acceptedTermsDate: undefined,
          acceptedTermsDateError: 'Failed to accept terms & conditions. Please try again.',
        },
      };
    case types.AUTH_REPRESENTATIVE_COMPLETED_REQUIRED_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          isRepresentativeMissingRequiredData: false,
        },
      };
    default:
      return state;
  }
}
