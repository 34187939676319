export const COMPONENTS_MEDICATIONS_LOAD_SUCCESS = 'COMPONENTS_MEDICATIONS_LOAD_SUCCESS';
export const COMPONENTS_MEDICATIONS_SPINNER_START = 'COMPONENTS_MEDICATIONS_SPINNER_START';
export const COMPONENTS_MEDICATIONS_SPINNER_STOP = 'COMPONENTS_MEDICATIONS_SPINNER_STOP';

export const COMPONENTS_MEDICATIONS_SHOW_ADD = 'COMPONENTS_MEDICATIONS_SHOW_ADD';
export const COMPONENTS_MEDICATIONS_CLOSE_ADD = 'COMPONENTS_MEDICATIONS_CLOSE_ADD';
export const COMPONENTS_SEARCH_MEDICATION_LOAD_SUCCESS =
  'COMPONENTS_SEARCH_MEDICATION_LOAD_SUCCESS';
export const COMPONENTS_DRUG_UNIT_LOAD_SUCCESS = 'COMPONENTS_DRUG_UNIT_LOAD_SUCCESS';
export const COMPONENTS_ADD_MEDICATION_RESET = 'COMPONENTS_ADD_MEDICATION_RESET';
export const COMPONENTS_ADD_MEDICATION_SUBMITTED = 'COMPONENTS_ADD_MEDICATION_SUBMITTED';
export const COMPONENTS_MEDICATIONS_SAVE_MEDICATION_SUCCESS =
  'COMPONENTS_MEDICATIONS_SAVE_MEDICATION_SUCCESS';
export const COMPONENTS_MEDICATIONS_SHOW_SAVE = 'COMPONENTS_MEDICATIONS_SHOW_SAVE';
export const COMPONENTS_MEDICATIONS_UPDATE_SELECTED_DRUG =
  'COMPONENTS_MEDICATIONS_UPDATE_SELETED_DRUG';
export const COMPONENTS_MEDICATIONS_UPDATE_SELECTED_DRUG_UNIT =
  'COMPONENTS_MEDICATIONS_UPDATE_SELETED_DRUG_UNIT';
