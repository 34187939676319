export const COMPONENTS_FAMILYHX_SPINNER_START = 'COMPONENTS_FAMILYHX_LOAD_START';
export const COMPONENTS_FAMILYHX_SPINNER_STOP = 'COMPONENTS_FAMILYHX_SPINNER_STOP';
export const COMPONENTS_FAMILYHX_LOAD_SUCCESS = 'COMPONENTS_FAMILYHX_LOAD_SUCCESS';
export const COMPONENTS_FAMILYHX_ADD_ROW = 'COMPONENTS_FAMILYHX_ADD_ROW';
export const COMPONENTS_SEARCH_FAMILYHX_CONST_LOAD_SUCCESS =
  'COMPONENTS_SEARCH_FAMILYHX_CONST_LOAD_SUCCESS';
export const COMPONENTS_FAMILYHX_SHOW_ERROR = 'COMPONENTS_FAMILYHX_SHOW_ERROR';
export const COMPONENTS_FAMILYHX_SAVE_SUCCESS = 'COMPONENTS_FAMILYHX_SAVE_SUCCESS';
export const COMPONENTS_FAMILYHX_DELETE_ROW = 'COMPONENTS_FAMILYHX_DELETE_ROW';
export const COMPONENTS_SEARCH_FAMILYHX_DIAG_LOAD_SUCCESS =
  'COMPONENTS_SEARCH_FAMILYHX_DIAG_LOAD_SUCCESS';
