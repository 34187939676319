import { legacy_createStore as createReduxStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import rootReducer from './reducers';
import { initialState } from './auth.state';

const composeEnhancers = composeWithDevTools({});

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const middleware = [thunk];
const store = createReduxStore(
  rootReducer,
  {
    auth: {
      ...initialState,
      externalAuthGlobalInfo: params
        ? {
            companyId: params['companyId'],
            signature: params['signature'],
            token: params['token'],
            patientRepresentativeId: params['patientRepresentativeId'],
          }
        : undefined,
    },
  },
  composeEnhancers(applyMiddleware(...middleware)),
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
