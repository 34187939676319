export const COMPONENTS_SOCIALHX_SPINNER_START = 'COMPONENTS_SOCIALHX_LOAD_START';
export const COMPONENTS_SOCIALHX_SPINNER_STOP = 'COMPONENTS_SOCIALHX_SPINNER_STOP';
export const COMPONENTS_SOCIALHX_LOAD_SUCCESS = 'COMPONENTS_SOCIALHX_LOAD_SUCCESS';
export const COMPONENTS_SOCIALHX_ADD_ROW = 'COMPONENTS_SOCIALHX_ADD_ROW';
export const COMPONENTS_SEARCH_SOCIALHX_CONST_LOAD_SUCCESS =
  'COMPONENTS_SEARCH_SOCIALHX_CONST_LOAD_SUCCESS';
export const COMPONENTS_SOCIALHX_SHOW_ERROR = 'COMPONENTS_SOCIALHX_SHOW_ERROR';
export const COMPONENTS_SOCIALHX_SAVE_SUCCESS = 'COMPONENTS_SOCIALHX_SAVE_SUCCESS';
export const COMPONENTS_SOCIALHX_DELETE_ROW = 'COMPONENTS_SOCIALHX_DELETE_ROW';
export const COMPONENTS_SEARCH_SOCIALHX_DIAG_LOAD_SUCCESS =
  'COMPONENTS_SEARCH_SOCIALHX_DIAG_LOAD_SUCCESS';
