import * as types from './constants';
import initialState from './initial.state';

export default function allergiesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_ALLERGIES_SPINNER_START:
      return {
        ...state,
        loading: true,
        isSubmitted: false,
        showError: false,
      };
    case types.COMPONENTS_ALLERGIES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.PatientPastHxAllergiesList,
      };
    case types.COMPONENTS_ALLERGIES_ADD_ROW:
      return {
        ...state,
        loading: false,
        data: action.newData,
      };
    case types.COMPONENTS_ALLERGIES_UPDATE_ROW:
      return {
        ...state,
        loading: false,
        data: action.newData,
      };
    case types.COMPONENTS_ALLERGIES_SEARCH_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        allergiesList: action.AllergiesList,
      };
    case types.COMPONENTS_ALLERGIES_REACTION_SEARCH_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        reactionList: action.ReactionList,
      };
    case types.COMPONENTS_ALLERGIES_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: true,
        data: action.data,
      };
    case types.COMPONENTS_ALLERGIES_DELETE_ROW:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSubmitted: false,
        showError: false,
      };
    case types.COMPONENTS_ALLERGIES_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: false,
      };
    case types.COMPONENTS_ALLERGIES_RESET:
      return {
        ...state,
        data: [],
        allergiesList: [],
        reactionList: [],
      };
    default:
      return state;
  }
}
