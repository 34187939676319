import {
  AppAccess,
  CompanyAccessPreferences,
  MasterCompanyDetails,
  PatientDetails,
} from '../../models/client';
import { WeavyConfigData } from '../../models/weavy/weavy-config-data.model';

export interface InitialState {
  appAccess?: AppAccess;
  companyAccessPreferences?: CompanyAccessPreferences;
  enableOnlinePayment?: boolean;
  error?: string;
  loading: boolean;
  masterCompanyDetails?: MasterCompanyDetails;
  patientDetails?: PatientDetails;
  reloadDashboard: boolean;
  weavyConfig?: WeavyConfigData;
  weavyConfigErrored: boolean;
}

const initialState: InitialState = {
  loading: true,
  reloadDashboard: false,
  weavyConfigErrored: false,
};

export default initialState;
