export const COMPONENTS_APPOINTMENTS_LOAD_SPINNER = 'COMPONENTS_APPOINTMENTS_LOAD_SPINNER';
export const COMPONENTS_PATIENT_APPOINTMENTS_LOAD = 'COMPONENTS_PATIENT_APPOINTMENTS_LOAD';
export const COMPONENT_APPOINTMENTS_TOGGLE_REQUEST_MODAL =
  'COMPONENT_APPOINTMENTS_TOGGLE_REQUEST_MODAL';
export const COMPONENTS_APPOINTMENT_MESSAGES_LOAD = 'COMPONENTS_APPOINTMENT_MESSAGES_LOAD';
export const COMPONENT_APPOINTMENTS_UPDATE_LAST_LOADED =
  'COMPONENT_APPOINTMENTS_UPDATE_LAST_LOADED';
export const COMPONENT_SCHEDULE_APPOINTMENTS_TOGGLE_MODAL =
  'COMPONENT_SCHEDULE_APPOINTMENTS_TOGGLE_MODAL';
export const COMPONENTS_APPOINTMENT_MASTER_LOAD_SUCCESS =
  'COMPONENTS_APPOINTMENT_MASTER_LOAD_SUCCESS';
export const COMPONENTS_COMPANY_APPLICATION_CONFIG_SUCCESS =
  'COMPONENTS_COMPANY_APPLICATION_CONFIG_SUCCESS';
export const COMPONENTS_HOME_MASTER_GET_COMPANY_MODULE_ACCESS_SUCCESS =
  'COMPONENTS_HOME_MASTER_GET_COMPANY_MODULE_ACCESS_SUCCESS';
export const COMPONENTS_HOME_MASTER_GET_COMPANY_MODULE_ACCESS_FAILURE =
  'COMPONENTS_HOME_MASTER_GET_COMPANY_MODULE_ACCESS_FAILURE';
export const COMPONENTS_HOME_MASTER_GET_V2PATIENT_LOAD_SUCCESS =
  'COMPONENTS_HOME_MASTER_GET_V2PATIENT_LOAD_SUCCESS';
export const COMPONENTS_HOME_MASTER_GET_V2PATIENT_LOAD_FAILED =
  'COMPONENTS_HOME_MASTER_GET_V2PATIENT_LOAD_FAILED';
export const COMPONENTS_HOME_MASTER_START_SPINNER = 'COMPONENTS_HOME_MASTER_START_SPINNER';
export const COMPONENTS_HOME_MASTER_STOP_SPINNER = 'COMPONENTS_HOME_MASTER_STOP_SPINNER';
export const COMPONENT_EDIT_SCHEDULED_APPOINTMENT_INFO =
  'COMPONENT_EDIT_SCHEDULED_APPOINTMENT_INFO';
export const COMPONENTS_APPOINTMENT_LOAD_STATUS_LIST_SUCCESS =
  'COMPONENTS_APPOINTMENT_LOAD_STATUS_LIST_SUCCESS';
export const COMPONENTS_APPOINTMENT_LOAD_STATUS_LIST_ERROR_OCCURED =
  'COMPONENTS_APPOINTMENT_LOAD_STATUS_LIST_ERROR_OCCURED';
export const COMPONENT_APPOINTMENTS_TOGGLE_PAST_MODAL = 'COMPONENT_APPOINTMENTS_TOGGLE_PAST_MODAL';
export const COMPONENT_APPOINTMENTS_REDIRECTED = 'COMPONENT_APPOINTMENTS_REDIRECTED';

export const PreferenceCode = {
  BUSINESS_START_TIME: 'BUSRT',
  BUSINESS_END_TIME: 'BUEDT',
  MONDAY_START_TIME: 'MOSRT',
  MONDAY_END_TIME: 'MOEDT',
  TUESDAY_START_TIME: 'TUSRT',
  TUESDAY_END_TIME: 'TUEDT',
  WEDNESDAY_START_TIME: 'WESRT',
  WEDNESDAY_END_TIME: 'WEEDT',
  THURSDAY_START_TIME: 'THSRT',
  THURSDAY_END_TIME: 'THEDT',
  FRIDAY_START_TIME: 'FRSRT',
  FRIDAY_END_TIME: 'FREDT',
  SATURDAY_START_TIME: 'SASRT',
  SATURDAY_END_TIME: 'SAEDT',
  SUNDAY_START_TIME: 'SUSRT',
  SUNDAY_END_TIME: 'SUEDT',
};
