import * as types from './constants';
import initialState from './initial.state';

export default function familyHxReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_FAMILYHX_SPINNER_START:
      return {
        ...state,
        loading: true,
        isSubmitted: false,
        showError: false,
      };
    case types.COMPONENTS_FAMILYHX_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.PatientFamilyHxList,
        isSubmitted: false,
        showError: false,
      };
    case types.COMPONENTS_FAMILYHX_ADD_ROW:
      return {
        ...state,
        loading: false,
        data: action.newData,
      };
    case types.COMPONENTS_FAMILYHX_DELETE_ROW:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSubmitted: false,
        showError: false,
      };

    case types.COMPONENTS_SEARCH_FAMILYHX_CONST_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        constants: action.response.FamilyMemberRelationList,
      };
    case types.COMPONENTS_SEARCH_FAMILYHX_DIAG_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        diagnosisList: action.DiagList,
      };
    case types.COMPONENTS_FAMILYHX_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: true,
        data: action.data,
      };
    case types.COMPONENTS_FAMILYHX_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: false,
      };
    default:
      return state;
  }
}
