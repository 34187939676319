import { Backdrop, CircularProgress } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
interface Props {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
}

interface SpinnerProps extends Props {
  fullScreen?: false;
}

interface FullScreenSpinnerProps extends Props {
  fullScreen: true;
  openBackdrop?: boolean;
  handleCloseBackdrop?: () => void;
}

const useStyles = makeStyles<Pick<Props, 'color'>>()((theme, { color }) => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    color: color ?? theme.palette.tertiary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: color ?? theme.palette.tertiary.main,
  },
}));

export const Spinner = (props: SpinnerProps | FullScreenSpinnerProps) => {
  const { classes } = useStyles({ color: props.color });

  const spinnerSize = useMemo(() => {
    if (props.fullScreen) {
      return 72;
    }

    switch (props.size) {
      case 'xs':
        return 12;
      case 'sm':
        return 24;
      case 'md':
        return 40;
      case 'lg':
        return 56;
      case 'xl':
        return 72;
      default:
        return 40;
    }
  }, [props.size]);

  const SpinnerWheel = () => <CircularProgress color="inherit" size={spinnerSize} />;

  return props.fullScreen ? (
    <Backdrop
      className={classes.backdrop}
      open={props.openBackdrop !== undefined ? props.openBackdrop : true}
      onClick={props.handleCloseBackdrop}
    >
      <SpinnerWheel />
    </Backdrop>
  ) : (
    <div className={classes.container}>
      <span className={classes.loader}>
        <SpinnerWheel />
      </span>
    </div>
  );
};
