export const COMPONENTS_LABS_LOAD_SUCCESS = 'COMPONENTS_LABS_LOAD_SUCCESS';
export const COMPONENTS_LABS_SPINNER_STOP = 'COMPONENTS_LABS_SPINNER_STOP';
export const COMPONENTS_LABS_SPINNER_START = 'COMPONENTS_LABS_SPINNER_START';
export const COMPONENTS_LABS_SHOW_ORDER = 'COMPONENTS_LABS_SHOW_ORDER';
export const COMPONENTS_LABS_SHOW_RESULTS = 'COMPONENTS_LABS_SHOW_RESULTS';
export const COMPONENTS_LABS_VIEW_COMMENTS = 'COMPONENTS_LABS_VIEW_COMMENTS';
export const COMPONENTS_LABS_HIDE_COMMENTS = 'COMPONENTS_LABS_HIDE_COMMENTS';
export const COMPONENTS_LABS_LOAD_FAILED = 'COMPONENTS_LABS_LOAD_FAILED';
export const COMPONENTS_LAB_DETAIL_LOADING = 'COMPONENTS_LAB_DETAIL_LOADING';
export const COMPONENTS_LAB_DETAIL_SUCCESS = 'COMPONENTS_LAB_DETAIL_SUCCESS';
export const COMPONENTS_LAB_DETAIL_ERROR = 'COMPONENTS_LAB_DETAIL_SUCCESS';
export const COMPONENTS_DOWNLOAD_LAB_SPINNER_START = 'COMPONENTS_DOWNLOAD_LAB_SPINNER_START';
export const COMPONENTS_DOWNLOAD_LAB_SPINNER_STOP = 'COMPONENTS_DOWNLOAD_LAB_SPINNER_STOP';
