import * as types from './constants';
import initialState from './initial.state';

export default function emailComfirmationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_EMAIL_CONFIRMATION_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_EMAIL_CONFIRMATION_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_EMAIL_CONFIRMATION_RESET_FORM:
      return {
        ...state,
        loading: false,
        isSubmitted: false,
        patientId: -1,
        validationMessages: [],
      };
    case types.COMPONENTS_EMAIL_CONFIRMATION_FORM_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.isSubmitted,
      };
    case types.COMPONENTS_EMAIL_CONFIRMATION_SUCCESS:
      return {
        ...state,
        patientId: action.response.PatientId,
        validationMessages: [],
      };
    case types.COMPONENTS_EMAIL_CONFIRMATION_ERROR_OCCURED:
      return {
        ...state,
        isSubmitted: false,
        validationMessages: action.errorObj.ValidationMessages,
      };
    default:
      return state;
  }
}
