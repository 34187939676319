export const COMPONENTS_MESSAGES_SPINNER_START = 'COMPONENTS_MESSAGES_SPINNER_START';
export const COMPONENTS_MESSAGES_SPINNER_STOP = 'COMPONENTS_MESSAGES_SPINNER_STOP';

export const COMPONENTS_MESSAGES_LOAD_PATIENT_MESSAGES_SUCCESS =
  'COMPONENTS_MESSAGES_LOAD_PATIENT_MESSAGES_SUCCESS';

export const COMPONENTS_MESSAGES_UPDATE_MESSAGE_SUCCESS =
  'COMPONENTS_MESSAGES_UPDATE_MESSAGE_SUCCESS';
export const COMPONENTS_MESSAGES_UPDATE_MESSAGE_FAILURE =
  'COMPONENTS_MESSAGES_UPDATE_MESSAGE_FAILURE';

export const COMPONENTS_MESSAGES_TOGGLE_CONFIRMATION_MODAL =
  'COMPONENTS_MESSAGES_TOGGLE_CONFIRMATION_MODAL';
export const COMPONENTS_MESSAGES_SET_USER_OPTION = 'COMPONENTS_MESSAGES_SET_USER_OPTION';
export const COMPONENTS_MESSAGES_RESET_FORM = 'COMPONENTS_MESSAGES_RESET_FORM';
export const COMPONENTS_MESSAGES_TOGGLE_SELECT_MESSAGE_MODAL =
  'COMPONENTS_MESSAGES_TOGGLE_SELECT_MESSAGE_MODAL';
