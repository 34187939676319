export default {
  data: [],
  enableOnlinePayment: false,
  generatedStatement: '',
  loading: false,
  patientDocument: null,
  showError: false,
  showStatementModal: false,
  statements: [],
};
