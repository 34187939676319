export const COMPONENTS_DOCUMENTS_LOAD_SUCCESS = 'COMPONENTS_DOCUMENTS_LOAD_SUCCESS';
export const COMPONENTS_DOCUMENTS_LOAD_FAILED = 'COMPONENTS_DOCUMENTS_LOAD_FAILED';
export const COMPONENTS_DOCUMENTS_SPINNER_STOP = 'COMPONENTS_DOCUMENTS_SPINNER_STOP';
export const COMPONENTS_DOCUMENTS_SPINNER_START = 'COMPONENTS_DOCUMENTS_SPINNER_START';
export const COMPONENTS_EHR_DOCUMENTS_GET_PATIENT_DOCUMENT_SUCCESS =
  'COMPONENTS_EHR_DOCUMENTS_GET_PATIENT_DOCUMENT_SUCCESS';
export const COMPONENTS_EHR_DOCUMENTS_GET_PATIENT_DOCUMENT_FAILED =
  'COMPONENTS_EHR_DOCUMENTS_GET_PATIENT_DOCUMENT_FAILED';
export const COMPONENT_PREVIEW_EHR_DOCUMENT_TOGGLE_MODAL =
  'COMPONENT_PREVIEW_EHR_DOCUMENT_TOGGLE_MODAL';
