import * as types from './constants';
import initialState from './initial.state';

export default function addRepresentativesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_REPRESENTATIVE_FORM_SAVE_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_REPRESENTATIVE_FORM_SAVE_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_REPRESENTATIVES_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
      };
    case types.COMPONENTS_REPRESENTATIVES_FORM_SET_NEW:
      return {
        ...state,
        isEdit: false,
        representative: undefined,
        cities: undefined,
      };
    case types.COMPONENTS_REPRESENTATIVES_FORM_SET_EDIT:
      return {
        ...state,
        isEdit: true,
      };
    case types.COMPONENTS_REPRESENTATIVES_TOGGLE_PASSWORD_CHANGE:
      return {
        ...state,
        isPasswordChange: action.isPasswordChange,
      };
    case types.COMPONENTS_LOAD_STATES_SUCCESS:
      return {
        ...state,
        states: action.response.StateList,
      };
    case types.COMPONENTS_LOAD_STATES_FAILURE:
      return {
        ...state,
        states: [],
      };
    case types.COMPONENTS_LOAD_MARITALSTATUSES_SUCCESS:
      return {
        ...state,
        maritalStatuses: action.response.MaritalStatusList,
      };
    case types.COMPONENTS_LOAD_MARITALSTATUSES_FAILURE:
      return {
        ...state,
        maritalStatuses: [],
      };
    case types.COMPONENTS_LOAD_PHONEPREFERENCETYPE_SUCCESS:
      return {
        ...state,
        phonePreferences: action.response.PhonePreferenceTypeList,
      };
    case types.COMPONENTS_LOAD_PHONEPREFERENCETYPE_FAILURE:
      return {
        ...state,
        phonePreferences: [],
      };
    case types.COMPONENTS_LOAD_PERSONRELATIONSHIP_SUCCESS:
      return {
        ...state,
        personRelationships: action.response.PersonRelationshipList,
      };
    case types.COMPONENTS_LOAD_PERSONRELATIONSHIP_FAILURE:
      return {
        ...state,
        personRelationships: [],
      };
    case types.COMPONENTS_LOAD_ZIPCODECITIES_SUCCESS:
      return {
        ...state,
        cities: action.response.ZipCodes,
      };
    case types.COMPONENTS_LOAD_ZIPCODECITIES_FAILURE:
      return {
        ...state,
        cities: [],
      };
    case types.COMPONENTS_REPRESENTATIVE_BY_ID_LOAD_SUCCESS:
      return {
        ...state,
        representative: action.data,
      };
    case types.COMPONENTS_REPRESENTATIVE_BY_ID_LOAD_FAILURE:
      return {
        ...state,
        representative: null,
      };
    default:
      return state;
  }
}
