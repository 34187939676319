import * as types from './constants';
import initialState from './initial.state';

export default function patientDocumentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_PATIENT_DOCUMENTS_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_PATIENT_DOCUMENTS_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_PATIENT_DOCUMENTS_FORM_SUBMITTED:
      return {
        ...state,
        isSubmitted: true,
      };
    case types.COMPONENTS_PATIENT_DOCUMENTS_RESET_STATE:
      return {
        ...state,
        validationMessages: null,
        ccdInfo: null,
        isSubmitted: false,
      };
    case types.COMPONENTS_PATIENT_DOCUMENTS_SET_DATES:
      return {
        ...state,
        fromDate: action.fromDate,
        toDate: action.toDate,
      };
    case types.COMPONENTS_PATIENT_DOCUMENTS_DOWNLOAD_CCD_SUCCESS:
      return {
        ...state,
        validationMessages: null,
        isSubmitted: false,
        ccdInfo: action.response,
      };
    case types.COMPONENTS_PATIENT_DOCUMENTS_DOWNLOAD_CCD_FAILURE:
      return {
        ...state,
        ccdInfo: null,
        validationMessages: action.error.ValidationMessages,
      };
    case types.COMPONENT_PREVIEW_CCD_TOGGLE_MODAL:
      return {
        ...state,
        showCcdPreviewer: action.show,
      };
    default:
      return state;
  }
}
