export const COMPONENTS_ALLERGIES_LOAD_SUCCESS = 'COMPONENTS_ALLERGIES_LOAD_SUCCESS';
export const COMPONENTS_ALLERGIES_SPINNER_START = 'COMPONENTS_ALLERGIES_SPINNER_START';
export const COMPONENTS_ALLERGIES_ADD_ROW = 'COMPONENTS_ALLERGIES_ADD_ROW';
export const COMPONENTS_ALLERGIES_SEARCH_LOAD_SUCCESS = 'COMPONENTS_ALLERGIES_SEARCH_LOAD_SUCCESS';
export const COMPONENTS_NEW_ALLERGIES_ADD_ROW = 'COMPONENTS_NEW_ALLERGIES_ADD_ROW';
export const COMPONENTS_ALLERGIES_SHOW_ERROR = 'COMPONENTS_ALLERGIES_SHOW_ERROR';
export const COMPONENTS_ALLERGIES_SAVE_SUCCESS = 'COMPONENTS_ALLERGIES_SAVE_SUCCESS';
export const COMPONENTS_ALLERGIES_DELETE_ROW = 'COMPONENTS_ALLERGIES_DELETE_ROW';
export const COMPONENTS_ALLERGIES_REACTION_SEARCH_LOAD_SUCCESS =
  'COMPONENTS_ALLERGIES_REACTION_SEARCH_LOAD_SUCCESS';

export const COMPONENTS_ALLERGIES_UPDATE_ROW = 'COMPONENTS_ALLERGIES_UPDATE_ROW';
export const COMPONENTS_ALLERGIES_RESET = 'COMPONENTS_ALLERGIES_RESET';
