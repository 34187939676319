import * as types from './constants';
import initialState from './initial-state';

export default function confirmPasswordReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_CONFIRM_PASSWORD_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_CONFIRM_PASSWORD_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_CONFIRM_PASSWORD_RESET_FORM:
      return {
        loading: false,
        isSubmitted: false,
        patientId: -1,
        validationMessages: [],
      };
    case types.COMPONENTS_CONFIRM_PASSWORD_FORM_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.isSubmitted,
      };
    case types.COMPONENTS_CONFIRM_PASSWORD_PATIENT_DETAIL_SUCCESS:
      return {
        ...state,
        patientUserName: action.response.PatientUserName,
        patientId: action.response.PatientId,
        patientRepresentativeId: action.response.PatientRepresentativeId,
        patientRepresentativeName: action.response.PatientRepresentativeName,
      };
    case types.COMPONENTS_CONFIRM_PASSWORD_PATIENT_DETAIL_FAILURE:
      return {
        ...state,
        patientName: action.isSubmitted,
      };
    case types.COMPONENTS_CONFIRM_PASSWORD_SUCCESS:
      return {
        ...state,
        patientId: action.patientId,
      };
    case types.COMPONENTS_CONFIRM_PASSWORD_ERROR_OCCURED:
      return {
        ...state,
        patientId: null,
      };
    default:
      return state;
  }
}
