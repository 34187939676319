import * as types from './constants';
import initialState from './initial.state';

export default function ehrDocumentsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_DOCUMENTS_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_DOCUMENTS_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_DOCUMENTS_LOAD_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case types.COMPONENTS_DOCUMENTS_LOAD_FAILED:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_EHR_DOCUMENTS_GET_PATIENT_DOCUMENT_SUCCESS:
      return {
        ...state,
        ehrPatientDocument: action.response,
      };
    case types.COMPONENTS_EHR_DOCUMENTS_GET_PATIENT_DOCUMENT_FAILED:
      return {
        ...state,
        ehrPatientDocument: null,
      };
    case types.COMPONENT_PREVIEW_EHR_DOCUMENT_TOGGLE_MODAL:
      return {
        ...state,
        showEhrDocumentPreviewer: !state.showEhrDocumentPreviewer,
      };
    default:
      return state;
  }
}
