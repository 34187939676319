import { User } from '../models/client';

export interface ExternalAuthGlobalInfo {
  companyId: string;
  signature: string;
  token: string;
  patientRepresentativeId: string;
}

interface InitialState {
  isLoggedIn: boolean;
  emailToken: string;
  user: User;
  externalAuthGlobalInfo?: ExternalAuthGlobalInfo;
}

export const initialState: InitialState = {
  user: undefined as any,
  isLoggedIn: false,
  emailToken: '',
};
