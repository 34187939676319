import * as types from './constants';
import initialState from './initial.state';

export default function loginV2Reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_LOGIN_FAILED:
      return {
        ...state,
        loginFailed: true,
        data: action.response,
      };
    case types.COMPONENTS_LOGIN_APPLICATION_CONFIG_SUCCESS:
      return {
        ...state,
        androidMobileAppStoreUrl: action.response.AndroidMobileAppStoreUrl,
        enableAppointmentRequest: action.response.EnableAppointmentRequest,
        enableForgotCredentials: action.response.EnableForgotCredentials,
        iosMobileAppStoreUrl: action.response.IosMobileAppStoreUrl,
        isAppConfigLoaded: true,
        loading: false,
      };
    default:
      return state;
  }
}
