export const COMPONENTS_ACTIVITIES_SPINNER_START = 'COMPONENTS_ACTIVITIES_SPINNER_START';
export const COMPONENTS_ACTIVITIES_SPINNER_STOP = 'COMPONENTS_ACTIVITIES_SPINNER_STOP';

export const COMPONENTS_ACTIVITIES_LOAD_SUCCESS = 'COMPONENTS_ACTIVITIES_LOAD_SUCCESS';
export const COMPONENTS_ACTIVITIES_LOAD_FAILURE = 'COMPONENTS_ACTIVITIES_LOAD_FAILURE';

export const COMPONENTS_ACTIVITIES_SEARCH_RESET = 'COMPONENTS_ACTIVITIES_SEARCH_RESET';
export const COMPONENTS_MESSAGES_SPINNER_START = 'COMPONENTS_MESSAGES_SPINNER_START';

export const COMPONENTS_MESSAGES_SPINNER_STOP = 'COMPONENTS_MESSAGES_SPINNER_STOP';
