export const COMPONENTS_APPOINTMENT_REQUEST_LOAD_LOCATIONS_SUCCESS =
  'COMPONENTS_APPOINTMENT_REQUEST_LOAD_LOCATIONS_SUCCESS';
export const COMPONENTS_APPOINTMENT_REQUEST_ERROR_OCCURED =
  'COMPONENTS_APPOINTMENT_REQUEST_ERROR_OCCURED';
export const COMPONENTS_APPOINTMENT_REQUEST_CHANGE_LOCATION =
  'COMPONENTS_APPOINTMENT_REQUEST_CHANGE_LOCATION';
export const COMPONENTS_APPOINTMENT_REQUEST_SUBMIT_FORM =
  'COMPONENTS_APPOINTMENT_REQUEST_SUBMIT_FORM';
export const COMPONENTS_APPOINTMENT_REQUEST_RESET_FORM =
  'COMPONENTS_APPOINTMENT_REQUEST_RESET_FORM';
export const COMPONENTS_APPOINTMENT_REQUEST_CREATE_APPOINTMENT_REQUEST_SUCCESS =
  'COMPONENTS_APPOINTMENT_REQUEST_CREATE_APPOINTMENT_REQUEST_SUCCESS';
export const COMPONENTS_APPOINTMENT_REQUEST_UPDATE_SELECTED_APPOINTMENT_DATE_LIST =
  'COMPONENTS_APPOINTMENT_REQUEST_UPDATE_SELECTED_APPOINTMENT_DATE_LIST';
export const COMPONENTS_APPOINTMENT_REQUEST_CLEAR_VALIDATION_MESSAGES =
  'COMPONENTS_APPOINTMENT_REQUEST_CLEAR_VALIDATION_MESSAGES';
export const COMPONENTS_APPOINTMENT_REQUEST_GET_TIME_INTERVALS =
  'COMPONENTS_APPOINTMENT_REQUEST_GET_TIME_INTERVALS';
