import * as types from './constants';
import initialState from './initial.state';

export default function transmitCCDReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_TRANSMIT_CCD_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_TRANSMIT_CCD_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_TRANSMIT_CCD_FORM_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.isSubmitted,
      };
    case types.COMPONENTS_TRANSMIT_CCD_FORM_RESET:
      return {
        ...state,
        loading: false,
        showSendSuccessMessage: false,
        isSubmitted: false,
        validationMessages: null,
      };
    case types.COMPONENTS_TRANSMIT_CCD_SEND_SUCCESS:
      return {
        ...state,
        showSendSuccessMessage: action.showSendSuccessMessage,
        isSubmitted: action.isSubmitted,
        validationMessages: null,
      };
    case types.COMPONENTS_TRANSMIT_CCD_SEND_FAILURE:
      return {
        ...state,
        validationMessages: action.error.ValidationMessages,
      };
    default:
      return state;
  }
}
