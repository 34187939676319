import { Action } from 'redux';
import { CompanyAccessPreferences } from '../../models/client';
import actionTypes from './constants';
import initialState, { InitialState } from './initial-state';

interface AuthenticatedAppAction extends Action {
  payload?: any;
}

const authenticatedAppReducer = (
  state: InitialState = initialState,
  action: AuthenticatedAppAction
) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATED_APP_START_SPINNER:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.AUTHENTICATED_APP_STOP_SPINNER:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.AUTHENTICATED_APP_GET_PATIENT_DETAILS_SUCCESS:
      return {
        ...state,
        patientDetails: action.payload,
      };
    case actionTypes.AUTHENTICATED_APP_GET_PATIENT_DETAILS_FAILURE:
      return {
        ...state,
        error: 'Failed to load patient details. Please reload the application and try again.',
      };
    case actionTypes.AUTHENTICATED_APP_GET_DOCTOR_COMPANY_MODULE_ACCESS_SUCCESS:
      return {
        ...state,
        appAccess: action.payload,
      };
    case actionTypes.AUTHENTICATED_APP_GET_DOCTOR_COMPANY_MODULE_ACCESS_FAILURE:
      return {
        ...state,
        appAccess: {
          hasEhrAccess: false,
          hasErxAccess: false,
          hasPmAccess: false,
          hasSchedulerAccess: false,
        },
      };
    case actionTypes.AUTHENTICATED_APP_GET_DOCTOR_COMPANY_LOGO_SUCCESS:
    case actionTypes.AUTHENTICATED_APP_GET_DOCTOR_COMPANY_LOGO_FAILURE:
      return {
        ...state,
        masterCompanyDetails: action.payload,
      };
    case actionTypes.AUTHENTICATED_APP_GET_COMPANY_ACCESS_PREFERENCES_SUCCESS:
    case actionTypes.AUTHENTICATED_APP_GET_COMPANY_ACCESS_PREFERENCES_FAILURE:
      return {
        ...state,
        companyAccessPreferences: action.payload as CompanyAccessPreferences,
      };
    case actionTypes.AUTHENTICATED_APP_GET_COMPANY_APPLICATION_CONFIGURATION_SUCCESS:
    case actionTypes.AUTHENTICATED_APP_GET_COMPANY_APPLICATION_CONFIGURATION_FAILURE:
      return { ...state, enableOnlinePayment: action.payload };
    case actionTypes.AUTHENTICATED_APP_TRIGGER_RELOAD_DASHBOARD:
      return {
        ...state,
        reloadDashboard: action.payload,
      };
    case actionTypes.AUTHENTICATED_APP_GET_WEAVY_USER_TOKEN_SUCCESS:
      return {
        ...state,
        weavyToken: action.payload,
      };
    case actionTypes.AUTHENTICATED_APP_GET_WEAVY_USER_TOKEN_FAILURE:
      return {
        ...state,
        weavyToken: null,
      };
    case actionTypes.AUTHENTICATED_APP_GET_WEAVY_CONFIG_SUCCESS:
      return {
        ...state,
        weavyConfig: action.payload,
        weavyConfigErrored: false,
      };
    case actionTypes.AUTHENTICATED_APP_GET_WEAVY_CONFIG_FAILURE:
      return {
        ...state,
        weavyConfig: null,
        weavyConfigErrored: true,
        loading: false,
      };
    default:
      return state;
  }
};

export default authenticatedAppReducer;
