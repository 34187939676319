import { Medication, UpcomingAppointment } from '../../models/client';

export interface InitialState {
  toDoNotifications?: {
    consentFormsCount?: number;
    intakeFormsCount?: number;
    pendingAppointmentsCount?: number;
    unreadMessagesCount?: number;
    rxSavingsCount?: number;
  };
  upcomingAppointments?: UpcomingAppointment[];
  upcomingAppointmentsError?: string;
  outstandingBalance?: number;
  outstandingBalanceError?: string;
  medications?: Medication[];
  medicationsError?: string;
}

const initialState: InitialState = {};

export default initialState;
