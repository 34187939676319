import * as types from './constants';
import initialState from './initial.state';

export default function medicationsMasterReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_MEDICATIONS_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_MEDICATIONS_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_MEDICATIONS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.PatientPastHxMedicationList,
      };
    case types.COMPONENTS_MEDICATIONS_SHOW_ADD:
      return {
        ...state,
        showAdd: true,
      };
    case types.COMPONENTS_MEDICATIONS_CLOSE_ADD:
      return {
        ...state,
        showAdd: false,
      };
    case types.COMPONENTS_SEARCH_MEDICATION_LOAD_SUCCESS:
      return {
        ...state,
        drugList: action.DrugList,
      };
    case types.COMPONENTS_DRUG_UNIT_LOAD_SUCCESS:
      return {
        ...state,
        drugUnits: action.DrugUnits,
        loading: false,
      };
    case types.COMPONENTS_ADD_MEDICATION_RESET:
      return {
        ...state,
        slectedDrug: {},
        slectedDrugUnit: {},
        drugList: [],
        drugUnits: [],
        isSubmitted: false,
        loading: false,
      };
    case types.COMPONENTS_ADD_MEDICATION_SUBMITTED:
      return {
        ...state,
        isSubmitted: true,
      };
    case types.COMPONENTS_MEDICATIONS_SAVE_MEDICATION_SUCCESS:
      return {
        ...state,
        isSubmitted: false,
        loading: false,
        showSave: false,
      };
    case types.COMPONENTS_MEDICATIONS_SHOW_SAVE:
      return {
        ...state,
        showSave: action.show,
        loading: false,
      };

    case types.COMPONENTS_MEDICATIONS_UPDATE_SELECTED_DRUG:
      return {
        ...state,
        slectedDrug: action.drug,
        loading: false,
      };
    case types.COMPONENTS_MEDICATIONS_UPDATE_SELECTED_DRUG_UNIT:
      return {
        ...state,
        slectedDrugUnit: action.drugunit,
        loading: false,
      };

    default:
      return state;
  }
}
