import * as types from './constants';
import initialState from './initial.state';

export default function implantableReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_IMPLANTABLE_SPINNER_START:
      return {
        ...state,
        loading: true,
        isSubmitted: false,
        showError: false,
      };
    case types.COMPONENTS_IMPLANTABLE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.PatientImplantableDeviceList,
      };
    case types.COMPONENTS_IMPLANTABLE_DEVICE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        implantableDeviceList: action.ImplantList,
      };

    case types.COMPONENTS_IMPLANTABLE_ADD_ROW:
      return {
        ...state,
        loading: false,
        data: action.newData,
      };
    case types.COMPONENTS_IMPLANTABLE_DELETE_ROW:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSubmitted: false,
        showError: false,
      };
    case types.COMPONENTS_IMPLANTABLE_DEVICE_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: true,
        data: action.data,
      };
    case types.COMPONENTS_IMPLANTABLE_DEVICE_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: false,
      };
    default:
      return state;
  }
}
