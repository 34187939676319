export default {
  loading: false,
  pastAppointments: [],
  futureAppointments: [],
  appointmentMessages: [],
  showRequestAppointmentModal: false,
  lastLoaded: '',
  masterPatientInfo: null,
  appointmentToEdit: {},
  showAppointmentPickerModal: true,
  appointmentStatusTypeList: [],
  showPastAppointmentModal: false,
  isRedirected: false,
  skipOutstandingBalanceCheck: false,
  showScheduleAppointmentModal: false,
};
