export const COMPONENTS_REPRESENTATIVE_FORM_SAVE_SPINNER_START =
  'COMPONENTS_REPRESENTATIVE_FORM_SAVE_SPINNER_START';
export const COMPONENTS_REPRESENTATIVE_FORM_SAVE_SPINNER_STOP =
  'COMPONENTS_REPRESENTATIVE_FORM_SAVE_SPINNER_STOP';

export const COMPONENTS_REPRESENTATIVES_FORM_SAVE_FAILED =
  'COMPONENTS_REPRESENTATIVES_FORM_SAVE_FAILED';

export const COMPONENTS_REPRESENTATIVES_FORM_SET_NEW = 'COMPONENTS_REPRESENTATIVES_FORM_SET_NEW';
export const COMPONENTS_REPRESENTATIVES_FORM_SET_EDIT = 'COMPONENTS_REPRESENTATIVES_FORM_SET_EDIT';

export const COMPONENTS_REPRESENTATIVES_TOGGLE_PASSWORD_CHANGE =
  'COMPONENTS_REPRESENTATIVES_TOGGLE_PASSWORD_CHANGE';
export const COMPONENTS_REPRESENTATIVES_PASSWORD_UPDATE_SUCCESS =
  'COMPONENTS_REPRESENTATIVES_PASSWORD_UPDATE_SUCCESS';

export const COMPONENTS_LOAD_STATES_SUCCESS = 'COMPONENTS_LOAD_STATES_SUCCESS';
export const COMPONENTS_LOAD_STATES_FAILURE = 'COMPONENTS_LOAD_STATES_FAILURE';

export const COMPONENTS_LOAD_MARITALSTATUSES_SUCCESS = 'COMPONENTS_LOAD_MARITALSTATUSES_SUCCESS';
export const COMPONENTS_LOAD_MARITALSTATUSES_FAILURE = 'COMPONENTS_LOAD_MARITALSTATUSES_FAILURE';

export const COMPONENTS_LOAD_PHONEPREFERENCETYPE_SUCCESS =
  'COMPONENTS_LOAD_PHONEPREFERENCETYPE_SUCCESS';
export const COMPONENTS_LOAD_PHONEPREFERENCETYPE_FAILURE =
  'COMPONENTS_LOAD_PHONEPREFERENCETYPE_FAILURE';

export const COMPONENTS_LOAD_PERSONRELATIONSHIP_SUCCESS =
  'COMPONENTS_LOAD_PERSONRELATIONSHIP_SUCCESS';
export const COMPONENTS_LOAD_PERSONRELATIONSHIP_FAILURE =
  'COMPONENTS_LOAD_PERSONRELATIONSHIP_FAILURE';

export const COMPONENTS_LOAD_ZIPCODECITIES_SUCCESS = 'COMPONENTS_LOAD_ZIPCODECITIES_SUCCESS';
export const COMPONENTS_LOAD_ZIPCODECITIES_FAILURE = 'COMPONENTS_LOAD_ZIPCODECITIES_FAILURE';

export const COMPONENTS_REPRESENTATIVE_BY_ID_LOAD_SUCCESS =
  'COMPONENTS_REPRESENTATIVE_BY_ID_LOAD_SUCCESS';
export const COMPONENTS_REPRESENTATIVE_BY_ID_LOAD_FAILURE =
  'COMPONENTS_REPRESENTATIVE_BY_ID_LOAD_FAILURE';
