import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';
import { LDProvider } from 'launchdarkly-react-client-sdk';
const clientId: string = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string;

interface Props {
  children?: React.ReactNode;
  customContextData?: RxntLaunchDarklyCustomContextData;
}

export type RxntLaunchDarklyCustomContextData = {
  patientEnrollmentEmbedToken?: string;
  [key: string]: string | number | undefined;
};

const RxntLaunchDarklyProvider = ({ children = undefined, customContextData = {} }: Props) => {
  const unauthenticatedLdContext: LDSingleKindContext = {
    kind: 'patient',
    key: 'unauthenticated',
    ...customContextData,
  };

  return (
    <LDProvider
      clientSideID={clientId}
      context={unauthenticatedLdContext}
      reactOptions={{ useCamelCaseFlagKeys: true }}
      options={{ sendEvents: false }}
    >
      {children}
    </LDProvider>
  );
};

export default RxntLaunchDarklyProvider;
