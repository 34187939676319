export default {
  loading: false,
  showSendSuccessMessage: false,
  maritalStatuses: undefined,
  personRelationships: undefined,
  phonePreferences: undefined,
  states: undefined,
  cities: undefined,
  representative: undefined,
  tableData: undefined,
  readOnlyProp: 'readonly',
  isEdit: false,
  isPasswordChange: false,
};
