export default {
  loading: false,
  searching: false,
  data: [],
  showAdd: false,
  drugList: [],
  drugUnits: [],
  isSubmitted: false,
  showSave: false,
  slectedDrug: {},
  slectedDrugUnit: {},
};
