export const COMPONENTS_EMAIL_CONFIRMATION_SPINNER_START =
  'COMPONENTS_EMAIL_CONFIRMATION_SPINNER_START';
export const COMPONENTS_EMAIL_CONFIRMATION_SPINNER_STOP =
  'COMPONENTS_EMAIL_CONFIRMATION_SPINNER_STOP';
export const COMPONENTS_EMAIL_CONFIRMATION_FORM_SUBMITTED =
  'COMPONENTS_EMAIL_CONFIRMATION_FORM_SUBMITTED';
export const COMPONENTS_EMAIL_CONFIRMATION_RESET_FORM = 'COMPONENTS_EMAIL_CONFIRMATION_RESET_FORM';
export const COMPONENTS_EMAIL_CONFIRMATION_SUCCESS = 'COMPONENTS_EMAIL_CONFIRMATION_SUCCESS';
export const COMPONENTS_EMAIL_CONFIRMATION_ERROR_OCCURED =
  'COMPONENTS_EMAIL_CONFIRMATION_ERROR_OCCURED';
