import React, { Component, ErrorInfo } from 'react';
import rxntBackground from '../assets/images/RXNT_bg.jpg';

interface RouterErrorBoundaryProps {
  children: React.ReactNode;
}

interface RouterErrorBoundaryState {
  hasError: boolean;
}

class RouterErrorBoundary extends Component<RouterErrorBoundaryProps, RouterErrorBoundaryState> {
  constructor(props: RouterErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log({ error, errorInfo });
  }

  render() {
    return this.state.hasError ? (
      <div
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: `url(${rxntBackground})`,
        }}
      >
        <p style={{ fontSize: '30px' }}>Error Occurred</p>
        <p>An unexpected error has occurred, please try again.</p>
        <p>
          If the issue continues, please contact our support team at{' '}
          <a href="mailto:support@rxnt.com">support@rxnt.com</a>
        </p>
      </div>
    ) : (
      this.props.children
    );
  }
}

export default RouterErrorBoundary;
