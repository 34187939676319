export default {
  loading: true,
  activities: [],
  data: {},
  activityTypes: [
    { id: 0, name: 'All' },
    { id: 1, name: 'Login' },
    { id: 2, name: 'Download File' },
    { id: 3, name: 'Direct Email Transmit' },
    { id: 4, name: 'Receive Email' },
    { id: 5, name: 'Email Transmit' },
    { id: 6, name: 'View File' },
    { id: 7, name: 'Skipped Cell Number' },
    { id: 8, name: 'Confirmed Cell Number' },
    { id: 9, name: 'Patient Feedback Accepted' },
    { id: 10, name: 'Patient Feedback Dismissed' },
    { id: 11, name: 'Survey Viewed' },
  ],
};
