const dashboardConstants = {
  DASHBOARD_RESET_DASHLETS_DATA: 'DASHBOARD_RESET_DASHLETS_DATA',
  DASHBOARD_START_SPINNER: 'DASHBOARD_START_SPINNER',
  DASHBOARD_STOP_SPINNER: 'DASHBOARD_STOP_SPINNER',
  DASHBOARD_GET_TODO_NOTIFICATIONS_COUNT: 'DASHBOARD_GET_TODO_NOTIFICATIONS_COUNT',
  DASHBOARD_GET_UPCOMING_APPOINTMENTS_SUCCESS: 'DASHBOARD_GET_UPCOMING_APPOINTMENTS_SUCCESS',
  DASHBOARD_GET_UPCOMING_APPOINTMENTS_FAILURE: 'DASHBOARD_GET_UPCOMING_APPOINTMENTS_FAILURE',
  DASHBOARD_GET_OUTSTANDING_BALANCE_SUCCESS: 'DASHBOARD_GET_OUTSTANDING_BALANCE_SUCCESS',
  DASHBOARD_GET_OUTSTANDING_BALANCE_FAILURE: 'DASHBOARD_GET_OUTSTANDING_BALANCE_FAILURE',
  DASHBOARD_GET_CURRENT_MEDICATIONS_SUCCESS: 'DASHBOARD_GET_CURRENT_MEDICATIONS_SUCCESS',
  DASHBOARD_GET_CURRENT_MEDICATIONS_FAILURE: 'DASHBOARD_GET_CURRENT_MEDICATIONS_FAILURE',
};

export default dashboardConstants;
