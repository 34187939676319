export default {
  loading: false,
  data: [],
  labDetailLoading: false,
  labDetail: null,
  labPdfLoading: false,
  showResults: true,
  showOrder: false,
  comments: false,
  startDate: {
    date: '',
  },
};
