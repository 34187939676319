import { register } from '@rxnt/ioc';
import React, { createContext, useContext, useLayoutEffect } from 'react';

import type { ZodiosEndpointDefinitions } from '@zodios/core';
import { useAppSelector } from '../store/hooks';
import { ClientCallback, ClientConfig, setupApiClient } from '../utils/setup-api-clients';
import { logoutIfUnauthorized } from '../utils/utils';

interface HttpResponse<ResponseObjectType> extends Response {
  parsedBody?: ResponseObjectType;
}

export interface AsyncResult<T> {
  data?: T;
  loading: boolean;
  error: boolean;
}

export interface ApiService {
  post: <RequestObjectType>(
    url: string,
    body: any,
    options?: RequestInit,
  ) => Promise<HttpResponse<RequestObjectType>>;
  get: <RequestObjectType>(
    url: string,
    options?: RequestInit,
  ) => Promise<HttpResponse<RequestObjectType>>;
}

/**
 * IOC key for a function that creates an API client.
 */
export const IOC_CREATE_API_CLIENT = 'api:create-api-client';

export const ApiServiceContext = createContext<ApiService | undefined>(undefined);

const baseUrl = process.env.REACT_APP_PHRAPISERVICES;

export const ioc = register([
  IOC_CREATE_API_CLIENT,
  <TEndpoints extends ZodiosEndpointDefinitions>(
    callback: ClientCallback<TEndpoints>,
    config?: ClientConfig,
  ) => setupApiClient(callback, config),
]);

type Props = {
  children?: React.ReactNode;
};
const ApiServiceProvider = ({ children }: Props) => {
  const auth = useAppSelector((store) => store.auth.user);

  useLayoutEffect(() => {
    if (auth) {
      ioc.register([
        IOC_CREATE_API_CLIENT,
        <TEndpoints extends ZodiosEndpointDefinitions>(
          callback: ClientCallback<TEndpoints>,
          config?: ClientConfig,
        ) => setupApiClient(callback, config, auth),
      ]);
    }
  }, [auth]);

  const value = {
    post: <ResponseObjectType,>(url: string, body: any, options?: RequestInit) => {
      const headers = prepareHeaders();
      const request: RequestInit = {
        ...options,
        credentials: 'same-origin',
        method: 'post',
        headers,
        body: JSON.stringify(body),
      };
      return apiService<ResponseObjectType>(new Request(baseUrl + url, request));
    },
    get: <ResponseObjectType,>(url: string, options?: RequestInit) => {
      const headers = prepareHeaders();
      const request: RequestInit = {
        ...options,
        credentials: 'same-origin',
        method: 'get',
        headers,
      };
      return apiService<ResponseObjectType>(new Request(baseUrl + url, request));
    },
  };

  const apiService = async <ResponseObjectType,>(
    request: RequestInfo,
  ): Promise<HttpResponse<ResponseObjectType>> => {
    const response: HttpResponse<ResponseObjectType> = await fetch(request);

    checkStatus(response);
    if (response.status === 204) response.parsedBody = undefined;
    else response.parsedBody = await response?.json();
    return response;
  };

  const prepareHeaders = () => {
    const headers = new Headers();

    headers.append('Content-Type', 'application/json');

    return headers;
  };

  const checkStatus = (response: HttpResponse<any>) => {
    switch (true) {
      case response.status >= 200 && response.status < 300:
        return Promise.resolve(response);
      case response.status === 401:
        logoutIfUnauthorized(401);
        return Promise.reject(response);
      default:
        return Promise.reject(response);
    }
  };

  return <ApiServiceContext.Provider value={value}>{children}</ApiServiceContext.Provider>;
};

export const useApiService = (): ApiService => {
  const context = useContext(ApiServiceContext);
  if (context === undefined) {
    throw new Error('useApiService must be used within an ApiServiceProvider');
  }
  return context;
};

export default ApiServiceProvider;
