import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// Define a base Analytics Config class
class AnalyticsConfig {
  private config: FirebaseOptions;
  constructor(config: FirebaseOptions) {
    this.config = config;
  }

  initialize() {
    try {
      const app = initializeApp(this.config);
      getAnalytics(app);
    } catch (err) {
      console.error('Failed to initialize Firebase Analytics', err);
    }
  }
}

// Test analytics config file
class TestFirebaseConfig extends AnalyticsConfig {
  constructor() {
    const config = {
      apiKey: process.env.REACT_APP_ANALYTICS_API_KEY,
      authDomain: 'patient-app-appdev-appqa.firebaseapp.com',
      projectId: 'patient-app-appdev-appqa',
      storageBucket: 'patient-app-appdev-appqa.appspot.com',
      messagingSenderId: '420988959764',
      appId: '1:420988959764:web:2a7b6ca006139f0b3f3b01',
      measurementId: 'G-7G47SPJE2C',
    };
    super(config);
  }
}

// Production analytics config file
class ProdFirebaseConfig extends AnalyticsConfig {
  constructor() {
    const config = {
      apiKey: process.env.REACT_APP_ANALYTICS_API_KEY,
      authDomain: 'patient-app-55b5b.firebaseapp.com',
      databaseURL: 'https://patient-app-55b5b.firebaseio.com',
      projectId: 'patient-app-55b5b',
      storageBucket: 'patient-app-55b5b.appspot.com',
      messagingSenderId: '1054897448563',
      appId: '1:1054897448563:web:d20124ff05df2f9950ec06',
      measurementId: 'G-7ZTMYGNWNC',
    };
    super(config);
  }
}

export default process.env.REACT_APP_LAUNCH_DARKLY_ENVIRONMENT === 'production'
  ? new ProdFirebaseConfig()
  : new TestFirebaseConfig();
