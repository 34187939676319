import * as types from './constants';
import initialState from './initial.state';

export default function documentsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_DOCUMENTS_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_DOCUMENTS_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };

    case types.COMPONENTS_PATIENT_PAYMENT_LOAD_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case types.COMPONENTS_PATIENT_PAYMENT_LOAD_FAILED:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_PATIENT_STATEMENTS_LOAD_SUCCESS:
      return {
        ...state,
        statements: action.data,
      };
    case types.COMPONENTS_PATIENT_STATEMENTS_LOAD_FAILED:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_COMPANY_APPLICATION_CONFIG_SUCCESS:
      return {
        ...state,
        enableOnlinePayment: action.enableOnlinePayment,
      };
    case types.COMPONENT_PATIENT_TOGGLE_STATEMENT_MODAL:
      if (!action.show) {
        return {
          ...state,
          generatedStatement: '',
          showStatementModal: action.show,
        };
      } else {
        return {
          ...state,
          showStatementModal: action.show,
        };
      }

    case types.COMPONENTS_PATIENT_GENERATED_STATEMENTS_LOAD_SUCCESS:
      return {
        ...state,
        generatedStatement: action.data,
      };
    default:
      return state;
  }
}
