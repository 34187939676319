export const COMPONENTS_CONFIRM_PASSWORD_SPINNER_START =
  'COMPONENTS_CONFIRM_PASSWORD_SPINNER_START';
export const COMPONENTS_CONFIRM_PASSWORD_SPINNER_STOP = 'COMPONENTS_CONFIRM_PASSWORD_SPINNER_STOP';
export const COMPONENTS_CONFIRM_PASSWORD_FORM_SUBMITTED =
  'COMPONENTS_CONFIRM_PASSWORD_FORM_SUBMITTED';
export const COMPONENTS_CONFIRM_PASSWORD_RESET_FORM = 'COMPONENTS_CONFIRM_PASSWORD_RESET_FORM';
export const COMPONENTS_CONFIRM_PASSWORD_SUCCESS = 'COMPONENTS_CONFIRM_PASSWORD_SUCCESS';
export const COMPONENTS_CONFIRM_PASSWORD_ERROR_OCCURED =
  'COMPONENTS_CONFIRM_PASSWORD_ERROR_OCCURED';
export const COMPONENTS_CONFIRM_PASSWORD_PASSWORD_MISMATCH =
  'COMPONENTS_CONFIRM_PASSWORD_PASSWORD_MISMATCH';
export const COMPONENTS_CONFIRM_PASSWORD_PATIENT_DETAIL_SUCCESS =
  'COMPONENTS_CONFIRM_PASSWORD_PATIENT_DETAIL_SUCCESS';
export const COMPONENTS_CONFIRM_PASSWORD_PATIENT_DETAIL_FAILURE =
  'COMPONENTS_CONFIRM_PASSWORD_PATIENT_DETAIL_FAILURE';
