import * as types from './constants';
import initialState from './initial.state';

export default function requestAppointmentReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_APPOINTMENT_REQUEST_LOAD_LOCATIONS_SUCCESS:
      return {
        ...state,
        validationMessages: [],
        locations: action.response.DoctorCompanyLocationList,
        providers: action.response.PersonResourceList,
        providerLocationMappings: action.response.PersonResourceDoctorGroupList,
        appointmentTypeList: action.response.CompanyAppointmentTemplateList,
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_ERROR_OCCURED:
      return {
        ...state,
        validationMessages: action.error.ValidationMessages,
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_CHANGE_LOCATION:
      return {
        ...state,
        selectedLocation: action.selectedLocation,
        providersForSelectedLocation: action.providersForSelectedLocation,
        validationMessages: [],
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_SUBMIT_FORM:
      return {
        ...state,
        isSubmitted: action.submit,
        validationMessages: [],
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_RESET_FORM:
      return {
        ...state,
        createAppointmentSuccess: false,
        isSubmitted: false,
        providersForSelectedLocation: [],
        selectedLocation: {},
        requestedDates: [],
        validationMessages: [],
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_CREATE_APPOINTMENT_REQUEST_SUCCESS:
      return {
        ...state,
        createAppointmentSuccess: action.createAppointmentSuccess,
        isSubmitted: action.isSubmitted,
        validationMessages: [],
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_UPDATE_SELECTED_APPOINTMENT_DATE_LIST:
      return {
        ...state,
        requestedDates: action.dateList,
        validationMessages: action.error.ValidationMessages,
        isSubmitted: false,
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_CLEAR_VALIDATION_MESSAGES:
      return {
        ...state,
        validationMessages: [],
      };
    case types.COMPONENTS_APPOINTMENT_REQUEST_GET_TIME_INTERVALS:
      return {
        ...state,
        appointmentTimeIntervals: action.times,
        validationMessages: [],
      };
    default:
      return state;
  }
}
