import type { ZodiosEndpointDefinitions, ZodiosInstance, ZodiosOptions } from '@zodios/core';
import { UAParser } from 'ua-parser-js';
import { z } from 'zod';

import { AxiosError, AxiosResponse } from 'axios';
import { ToCamel, toCamel, toPascal } from './camel-pascal-case';
import { logoutIfUnauthorized } from './utils';

const { getBrowser, getDevice } = new UAParser('user-agent');

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

export type ApiAuth = {
  token: string;
  signature: string;
  patientId: number;
  doctorCompanyId: number;
  doctorGroupId: number;
  patientRepresentativeId?: number;
  loggedInUserId: number;
  doctorId: number;
  loginId: number;
};

export type ClientCallback<TEndpoints extends ZodiosEndpointDefinitions> = (
  baseUrl: string,
  consumerName: string,
  options?: ZodiosOptions,
) => ZodiosInstance<TEndpoints>;

export type ClientConfig = {
  rootUrl?: string | undefined;
  browser?:
    | {
        name: string | undefined;
        device: string | undefined;
      }
    | undefined;
};

/**
 * IOC key for a user's auth object.
 * @see {ApiAuth}
 */
export const IOC_USER_API_AUTH = 'user:api-auth';

export const setupApiClient = <TEndpoints extends ZodiosEndpointDefinitions>(
  createClient: ClientCallback<TEndpoints>,
  {
    rootUrl = process.env.REACT_APP_PHRAPISERVICES,
    browser = { name: getBrowser().name, device: getDevice().model },
  }: ClientConfig = {},
  auth?: ApiAuth,
) => {
  type ApiInstance = ReturnType<typeof createClient>;
  type ApiEndpoints = ApiInstance extends ZodiosInstance<infer TEndpoints> ? TEndpoints : never;
  type ApiMethods = ApiEndpoints[number]['method'];
  type ApiPaths = ApiEndpoints[number]['path'];
  type ApiData<TPath extends ApiPaths, TMethod extends ApiMethods = 'post'> =
    Extract<ApiEndpoints[number], { path: TPath; method: TMethod }> extends {
      parameters: Array<{ schema: z.ZodSchema<infer TSchema> }>;
    }
      ? Prettify<
          Partial<Pick<ApiAuth, 'patientRepresentativeId'>> & Omit<ToCamel<TSchema>, keyof ApiAuth>
        >
      : never;
  type Api<TPath extends ApiPaths, TMethod extends ApiMethods = 'post'> = ZodiosInstance<
    [Extract<ApiEndpoints[number], { method: TMethod; path: TPath }>]
  >;

  const api = createClient(rootUrl, 'phr-site', {});
  api.axios.interceptors.request.use((config: any) => {
    if (auth) {
      config.headers.set('token', auth?.token);
      config.headers.set('signature', auth.signature);
      config.headers.set('patientId', auth.patientId);
      config.headers.set('doctorCompanyId', auth.doctorCompanyId);
    }
    config.headers.set('x-browser', browser.name);
    config.headers.set('x-device', browser.device);

    return config;
  });

  api.axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      logoutIfUnauthorized(error.response?.status);

      if (error.response) {
        // Handle all API failures with status codes outside of the 2xx range
        return Promise.resolve(error.response);
      } else if (error.request) {
        // Request made without receiving response
        console.error('Axios request error: ', error.request);
      } else {
        console.error('Axios error: ', error.message);
      }

      return Promise.reject(error);
    },
  );

  const bindDataToRequest = <TPath extends ApiPaths>(
    convertToPascal: Boolean,
    data?: ApiData<TPath, 'post'>,
  ) => {
    const body = {
      ...(auth || {}),
      ...data,
    };

    return convertToPascal ? toPascal(body) : body;
  };

  return {
    auth: auth ? toPascal(auth) : undefined,
    client: api,
    post: async <TPath extends ApiPaths>(path: TPath, data?: ApiData<TPath, 'post'>) => {
      const convertToPascal = !path.includes('phrNodeApiInterface');
      const fn = api.post as Api<TPath, 'post'>['post'];
      return fn
        .bind(api)(
          path as any,
          bindDataToRequest(convertToPascal, data) as unknown as any,
          ...([] as any[]),
        )
        .then(toCamel);
    },
    get: async <TPath extends ApiPaths, TQuery extends { params: any }>(
      path: TPath,
      query?: TQuery,
    ) => {
      const fn = api.get as Api<TPath, 'get'>['get'];

      let pathWithQuery = `${path}`;
      if (query?.params) {
        Object.entries(query!.params).forEach(([key, value]) => {
          pathWithQuery = pathWithQuery.replace(`:${key}`, value as string);
          pathWithQuery = pathWithQuery.replace(`?${key}`, `?${value}`);
        });
      }
      return fn
        .bind(api)(pathWithQuery as any, ...([] as any[]))
        .then(toCamel);
    },
  };
};
