import store from '../store/store';
import * as types from '../store/constants';
import { toastr } from 'react-redux-toastr';

export const formatUtcDatetime = (utcDate: string) => {
  return new Intl.DateTimeFormat('en-us', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: 'numeric',
  }).format(new Date(utcDate));
};

export const logoutIfUnauthorized = (statusCode: number | undefined) => {
  if (statusCode === 401) {
    store?.dispatch({
      type: types.STORE_LOGOUT_USER,
    });
    window.location.href = process.env.REACT_APP_HOSTED_IP;
    toastr.error('', 'Your session has logged out due to inactivity.');
  }
};
