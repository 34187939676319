export default {
  validationMessages: [],
  locations: [],
  providersForSelectedLocation: [],
  selectedLocation: {},
  providers: [],
  providerLocationMappings: [],
  requestedDates: [],
  isSubmitted: false,
  appointmentTimeIntervals: [],
  appointmentTypeList: [],
};
