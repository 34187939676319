import * as types from './constants';
import initialState from './initial.state';

export default function appointmentsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_APPOINTMENTS_LOAD_SPINNER:
      return {
        ...state,
        loading: action.show,
      };
    case types.COMPONENTS_PATIENT_APPOINTMENTS_LOAD:
      return {
        ...state,
        pastAppointments: action.response.PastAppointments,
        futureAppointments: action.response.FutureAppointments,
      };
    case types.COMPONENTS_APPOINTMENT_MESSAGES_LOAD:
      return {
        ...state,
        appointmentMessages: action.response.appointmentRequests,
        submitAppointmentSuccess: false,
      };
    case types.COMPONENT_APPOINTMENTS_TOGGLE_REQUEST_MODAL:
      return {
        ...state,
        showRequestAppointmentModal: action.show,
      };
    case types.COMPONENT_SCHEDULE_APPOINTMENTS_TOGGLE_MODAL:
      return {
        ...state,
        showScheduleAppointmentModal: action.show,
        showScheduleAppointmentModalMode: action.mode,
      };
    case types.COMPONENT_APPOINTMENTS_UPDATE_LAST_LOADED:
      return {
        ...state,
        lastLoaded: new Date(),
      };
    case types.COMPONENTS_APPOINTMENT_MASTER_LOAD_SUCCESS:
      return {
        ...state,
        data: action.response.homeData,
      };
    case types.COMPONENTS_HOME_MASTER_GET_COMPANY_MODULE_ACCESS_SUCCESS:
      return {
        ...state,
        hasSchedulerV2Access: action.hasSchedulerV2Access,
        hasPMV2Access: action.hasPMV2Access,
      };
    case types.COMPONENTS_HOME_MASTER_GET_COMPANY_MODULE_ACCESS_FAILURE:
      return {
        ...state,
        hasSchedulerV2Access: false,
        hasPMV2Access: false,
      };
    case types.COMPONENTS_HOME_MASTER_START_SPINNER:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_HOME_MASTER_STOP_SPINNER:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_HOME_MASTER_GET_V2PATIENT_LOAD_SUCCESS:
      return {
        ...state,
        masterPatientInfo: action.response,
      };
    case types.COMPONENTS_HOME_MASTER_GET_V2PATIENT_LOAD_FAILED:
      return {
        ...state,
        masterPatientInfo: null,
      };
    case types.COMPONENT_EDIT_SCHEDULED_APPOINTMENT_INFO:
      return {
        ...state,
        appointmentToEdit: action.appointmentInfo,
        showScheduleAppointmentModal: action.show,
        showScheduleAppointmentModalMode: action.mode,
      };
    case types.COMPONENTS_APPOINTMENT_LOAD_STATUS_LIST_SUCCESS:
      return {
        ...state,
        appointmentStatusTypeList: action.response.ApplicationTableConstantsList,
      };
    case types.COMPONENTS_APPOINTMENT_LOAD_STATUS_LIST_ERROR_OCCURED:
      return {
        ...state,
        appointmentStatusTypeList: [],
      };
    case types.COMPONENT_APPOINTMENTS_TOGGLE_PAST_MODAL:
      return {
        ...state,
        showPastAppointmentModal: action.show,
      };
    case types.COMPONENT_APPOINTMENTS_REDIRECTED:
      return {
        ...state,
        isRedirected: action.isRedirected,
      };
    default:
      return state;
  }
}
