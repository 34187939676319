import * as types from './constants';
import initialState from './initial.state';

export default function addDocumentsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_ADD_DOCUMENTS_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_ADD_DOCUMENTS_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_ADD_DOCUMENTS_SUBMITTED:
      return {
        ...state,
        isSubmitted: true,
      };
    case types.COMPONENTS_SAVE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isSubmitted: false,
      };
    case types.COMPONENTS_SAVE_DOCUMENTS_FAILURE:
      return {
        ...state,
        isSubmitted: false,
      };
    case types.COMPONENTS_ADD_DOCUMENTS_RESET:
      return {
        ...state,
        isSubmitted: false,
      };
    case types.COMPONENTS_LOAD_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: action.response.Doctors,
      };
    case types.COMPONENTS_LOAD_DOCTORS_FAILURE:
      return {
        ...state,
        doctors: [],
      };
    default:
      return state;
  }
}
