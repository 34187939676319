import React from 'react';
import Props from '../models/component-props.model';
import ApiServiceProvider from './api-service.context';
import AppDataProvider from './app-data.context';

const AppProvider = ({ children }: Props) => (
  <ApiServiceProvider>
    <AppDataProvider>{children}</AppDataProvider>
  </ApiServiceProvider>
);

export default AppProvider;
