import * as types from './constants';
import initialState from './initial.state';

export default function messagesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_MESSAGES_SPINNER_START:
      return {
        ...state,
        loading: true,
      };
    case types.COMPONENTS_MESSAGES_SPINNER_STOP:
      return {
        ...state,
        loading: false,
      };
    case types.COMPONENTS_MESSAGES_TOGGLE_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmationDialog: action.show,
      };
    case types.COMPONENTS_MESSAGES_SET_USER_OPTION:
      return {
        ...state,
        showConfirmationDialog: action.show,
        userOptedNotToShowDialog: action.userOptedNotToShowDialog,
      };
    case types.COMPONENTS_MESSAGES_UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        showUpdateMessageSuccessMessage: action.showUpdateMessageSuccessMessage,
      };
    case types.COMPONENTS_MESSAGES_RESET_FORM:
      return {
        ...state,
        showUpdateMessageSuccessMessage: false,
        showUpdateMessageFailMessage: false,
      };
    case types.COMPONENTS_MESSAGES_LOAD_PATIENT_MESSAGES_SUCCESS:
      return {
        ...state,
        outboxMessages: action.outboxMessages,
        inboxMessages: action.inboxMessages,
      };
    case types.COMPONENTS_MESSAGES_UPDATE_MESSAGE_FAILURE:
      return {
        ...state,
        showUpdateMessageFailMessage: true,
      };
    default:
      return state;
  }
}
