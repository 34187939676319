export const COMPONENTS_PATIENT_PAYMENT_LOAD_SUCCESS = 'COMPONENTS_PATIENT_PAYMENT_LOAD_SUCCESS';
export const COMPONENTS_PATIENT_PAYMENT_LOAD_FAILED = 'COMPONENTS_PATIENT_PAYMENT_LOAD_FAILED';
export const COMPONENTS_PATIENT_STATEMENTS_LOAD_SUCCESS =
  'COMPONENTS_PATIENT_STATEMENTS_LOAD_SUCCESS';
export const COMPONENTS_PATIENT_STATEMENTS_LOAD_FAILED =
  'COMPONENTS_PATIENT_STATEMENTS_LOAD_FAILED';
export const COMPONENTS_DOCUMENTS_SPINNER_STOP = 'COMPONENTS_DOCUMENTS_SPINNER_STOP';
export const COMPONENTS_DOCUMENTS_SPINNER_START = 'COMPONENTS_DOCUMENTS_SPINNER_START';
export const COMPONENTS_COMPANY_APPLICATION_CONFIG_SUCCESS =
  'COMPONENTS_COMPANY_APPLICATION_CONFIG_SUCCESS';
export const COMPONENT_PATIENT_TOGGLE_STATEMENT_MODAL = 'COMPONENT_PATIENT_TOGGLE_STATEMENT_MODAL';
export const COMPONENTS_PATIENT_GENERATED_STATEMENTS_LOAD_SUCCESS =
  'COMPONENTS_PATIENT_GENERATED_STATEMENTS_LOAD_SUCCESS';
