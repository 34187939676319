import { reducer as toastrReducer } from 'react-redux-toastr';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authenticatedAppReducer from '../app/authenticated-app/reducer';
import activitiesReducer from '../components/activities/reducer';
import addDocumentReducer from '../components/add-document/reducer';
import addRepresentativesReducer from '../components/add-representative/reducer';
import billingSummaryReducer from '../components/billing-summary/reducer';
import confirmPasswordReducer from '../components/confirm-password/reducer';
import newDashboardReducer from '../components/dashboard-new/reducer';
import ehrDocumentsReducer from '../components/documents/ehr-patient-documents/reducer';
import patientDocumentReducer from '../components/documents/patient-documents/reducer';
import emailComfirmationReducer from '../components/email-confirmation/reducer';
import familyHxReducer from '../components/familyhx/reducer';
import labsReducer from '../components/labs/reducer';
import loginV2Reducer from '../components/login/reducer';
import allergiesReducer from '../components/medical/allergies/reducer';
import hospitalizationHxReducer from '../components/medical/hospitalizationhx/reducer';
import implantableDeviceHistoryReducer from '../components/medical/implantable-device-history/reducer';
import medicalHxReducer from '../components/medical/medicalhx/reducer';
import medicationsReducer from '../components/medical/medications/reducer';
import medicalReducer from '../components/medical/reducer';
import surgicalHxReducer from '../components/medical/surgicalhx/reducer';
import messagesReducer from '../components/messages/reducer';
import requestAppointmentReducer from '../components/patient-appointments/appointment-request/reducer';
import appointmentsReducer from '../components/patient-appointments/reducer';
import scheduleAppointmentReducer from '../components/patient-appointments/schedule-appointment/reducer';
import socialHxReducer from '../components/socialhx/reducer';
import transmitCCDReducer from '../components/transmit-ccd/reducer';
import timerReducer from '../uicomponents/forms/timer/reducer';
import authReducer from './auth.reducer';

const appReducer = combineReducers({
  activitiesReducer,
  addRepresentativesReducer,
  addDocumentReducer,
  allergiesReducer,
  appointmentsReducer,
  auth: authReducer,
  authenticatedAppReducer,
  billingSummaryReducer,
  confirmPasswordReducer,
  ehrDocumentsReducer,
  emailComfirmationReducer,
  familyHxReducer,
  form: formReducer,
  hospitalizationHxReducer,
  implantableDeviceHistoryReducer,
  labsReducer,
  loginV2Reducer,
  medicalHxReducer,
  medicalReducer,
  medicationsReducer,
  messagesReducer,
  newDashboardReducer,
  patientDocumentReducer,
  requestAppointmentReducer,
  routing: routerReducer,
  scheduleAppointmentReducer,
  socialHxReducer,
  surgicalHxReducer,
  timerReducer,
  toastr: toastrReducer,
  transmitCCDReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'STORE_LOGOUT_USER') {
    const prevState = state;
    state = {
      auth: {
        enableNewBranding: prevState.auth.enableNewBranding,
      },
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
