export const COMPONENTS_HOSPITALIZATIONHX_LOAD_SUCCESS =
  'COMPONENTS_HOSPITALIZATIONHX_LOAD_SUCCESS';
export const COMPONENTS_HOSPITALIZATIONHX_SPINNER_START =
  'COMPONENTS_HOSPITALIZATIONHX_SPINNER_START';
export const COMPONENTS_HOSPITALIZATIONHX_ADD_ROW = 'COMPONENTS_HOSPITALIZATIONHX_ADD_ROW';
export const COMPONENTS_SEARCH_HOSP_DIAG_LOAD_SUCCESS = 'COMPONENTS_SEARCH_HOSP_DIAG_LOAD_SUCCESS';
export const COMPONENTS_HOSPITALIZATIONHX_SHOW_ERROR = 'COMPONENTS_HOSPITALIZATIONHX_SHOW_ERROR';
export const COMPONENTS_HOSPITALIZATIONHX_SAVE_SUCCESS =
  'COMPONENTS_HOSPITALIZATIONHX_SAVE_SUCCESS';
export const COMPONENTS_HOSPITALIZATIONHX_DELETE_ROW = 'COMPONENTS_HOSPITALIZATIONHX_DELETE_ROW';
