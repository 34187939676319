import * as types from './constants';
import initialState from './initial.state';

export default function problemsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.COMPONENTS_SURGICALHX_SPINNER_START:
      return {
        ...state,
        loading: true,
        isSubmitted: false,
        showError: false,
      };
    case types.COMPONENTS_SURGICALHX_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.PatientSurgicalHxList,
      };
    case types.COMPONENTS_SURGICALHX_ADD_ROW:
      return {
        ...state,
        loading: false,
        data: action.newData,
      };
    case types.COMPONENTS_SEARCH_SURGICALHX_DIAG_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        diagnosisList: action.DiagList,
      };
    case types.COMPONENTS_SURGICALHX_SHOW_ERROR:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: true,
        data: action.data,
      };
    case types.COMPONENTS_SURGICALHX_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmitted: true,
        showError: false,
      };
    case types.COMPONENTS_SURGICALHX_DELETE_ROW:
      return {
        ...state,
        loading: false,
        data: action.data,
        isSubmitted: false,
        showError: false,
      };
    default:
      return state;
  }
}
