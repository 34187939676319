import React, { createContext, useContext, useEffect, useState } from 'react';
import appSettings from '../app.constants';
import { BaseWebApiRequest } from '../models/api';
import { GroupConfigResponse } from '../models/api/group-config-response.model';
import Props from '../models/component-props.model';
import { useAppSelector } from '../store/hooks';
import { AsyncResult, useApiService } from './api-service.context';
import { UserType } from '../models/client';
import analytics from '../utils/analytics/analytics';

type GetGroupTimeZoneFunction = (doctorGroupId: number) => AsyncResult<string>;

export interface AppDataContextValue {
  getGroupTimeZoneByGroupId: GetGroupTimeZoneFunction;
  userType: UserType;
  setUserType: (type: UserType) => void;
}

export const AppDataContext = createContext<AppDataContextValue | undefined>(undefined);

const AppDataProvider = ({ children }: Props) => {
  const user = useAppSelector((store) => store.auth.user);
  const { post } = useApiService();
  const [groupConfig, setGroupConfig] = useState<AsyncResult<GroupConfigResponse>>({
    data: undefined,
    loading: true,
    error: false,
  });
  const [userType, setUserType] = useState<UserType>('PATIENT');

  const getGroupTimeZoneByGroupId: GetGroupTimeZoneFunction = (doctorGroupId) => {
    if (!groupConfig.data) {
      return { ...groupConfig, data: undefined };
    }

    const group = groupConfig.data?.GroupsTimeZonePreferences.find(
      (e) => e.DoctorGroupId === doctorGroupId
    );
    const defaultTimezoneCode = 'ET';
    const timeZoneCode = group ? group.TimeZoneCode : defaultTimezoneCode;

    return { data: timeZoneCode, loading: false, error: false };
  };

  useEffect(() => {
    analytics.initialize();
    if (!user) {
      return;
    }

    async function fetchGroupConfig() {
      const { doctorCompanyId, patientId, token, signature } = user;
      const body: BaseWebApiRequest = {
        DoctorCompanyId: doctorCompanyId,
        PatientId: patientId,
        Signature: signature,
        Token: token,
      };

      try {
        const groupConfig = (
          await post<GroupConfigResponse>(
            appSettings.phrV2WebApiRoutes.getCompanyGroupTimeZonesMasterRoute,
            body
          )
        )?.parsedBody;
        setGroupConfig({ data: groupConfig, loading: false, error: !!groupConfig });
      } catch (e) {
        setGroupConfig({ data: undefined, loading: false, error: true });
      }
    }

    fetchGroupConfig();
  }, [post, user]);

  return (
    <AppDataContext.Provider value={{ getGroupTimeZoneByGroupId, userType, setUserType }}>
      {children}
    </AppDataContext.Provider>
  );
};

export const useAppData = (): AppDataContextValue => {
  const context = useContext(AppDataContext);
  if (context === undefined) {
    throw new Error('useAppData must be used within a AppDataProvider');
  }
  return context;
};

export default AppDataProvider;
