import initialState, { InitialState } from './initial-state';

import { Action } from 'redux';
import actionTypes from './constants';
import { Medication } from '../../models/client';

interface DashboardAction extends Action {
  payload?: any;
}

const dashboardReducer = (state = initialState, action: DashboardAction): InitialState => {
  let dashboardState = state;

  switch (action.type) {
    case actionTypes.DASHBOARD_GET_TODO_NOTIFICATIONS_COUNT:
      dashboardState = {
        ...state,
        toDoNotifications: action.payload,
      };
      break;
    case actionTypes.DASHBOARD_GET_UPCOMING_APPOINTMENTS_SUCCESS:
      dashboardState = {
        ...state,
        upcomingAppointments: action.payload,
        upcomingAppointmentsError: undefined,
      };
      break;
    case actionTypes.DASHBOARD_GET_UPCOMING_APPOINTMENTS_FAILURE:
      dashboardState = {
        ...state,
        upcomingAppointments: undefined,
        upcomingAppointmentsError: 'Failed to load upcoming appointments',
      };
      break;
    case actionTypes.DASHBOARD_GET_OUTSTANDING_BALANCE_SUCCESS:
      dashboardState = {
        ...state,
        outstandingBalance: action.payload as number,
        outstandingBalanceError: undefined,
      };
      break;
    case actionTypes.DASHBOARD_GET_OUTSTANDING_BALANCE_FAILURE:
      dashboardState = {
        ...state,
        outstandingBalance: undefined,
        outstandingBalanceError:
          'Failed to load outstanding balance. Please click "Pay" to view your balance and pay your bill.',
      };
      break;
    case actionTypes.DASHBOARD_GET_CURRENT_MEDICATIONS_SUCCESS:
      dashboardState = {
        ...state,
        medications: action.payload as Medication[],
        medicationsError: undefined,
      };
      break;
    case actionTypes.DASHBOARD_GET_CURRENT_MEDICATIONS_FAILURE:
      dashboardState = {
        ...state,
        medications: undefined,
        medicationsError: 'Failed to load current medications',
      };
      break;
    case actionTypes.DASHBOARD_RESET_DASHLETS_DATA:
      dashboardState = {
        ...initialState,
      };
      break;
    default:
      break;
  }

  return dashboardState;
};

export default dashboardReducer;
