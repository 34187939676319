export const COMPONENTS_ADD_DOCUMENTS_SPINNER_START = 'COMPONENTS_ADD_DOCUMENTS_SPINNER_START';
export const COMPONENTS_ADD_DOCUMENTS_SPINNER_STOP = 'COMPONENTS_ADD_DOCUMENTS_SPINNER_STOP';

export const COMPONENTS_LOAD_DOCTORS_SUCCESS = 'COMPONENTS_LOAD_DOCTORS_SUCCESS';
export const COMPONENTS_LOAD_DOCTORS_FAILURE = 'COMPONENTS_LOAD_DOCTORS_FAILURE';

export const COMPONENTS_SAVE_DOCUMENTS_SUCCESS = 'COMPONENTS_SAVE_DOCUMENTS_SUCCESS';
export const COMPONENTS_SAVE_DOCUMENTS_FAILURE = 'COMPONENTS_SAVE_DOCUMENTS_FAILURE';

export const COMPONENTS_ADD_DOCUMENTS_SUBMITTED = 'COMPONENTS_ADD_DOCUMENTS_SUBMITTED';

export const COMPONENTS_ADD_DOCUMENTS_RESET = 'COMPONENTS_ADD_DOCUMENTS_RESET';
