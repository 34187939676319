import { lazy } from 'react';
import { useLocation } from 'react-router-dom';
import appConstants from '../app.constants';
import { useAppSelector } from '../store/hooks';
import RxntLaunchDarklyProvider, {
  RxntLaunchDarklyCustomContextData,
} from '../utils/launch-darkly/launch-darkly-provider';

const UnauthenticatedApp = lazy(() => import('./unauthenticated-app'));
const AuthenticatedApp = lazy(() => import('./authenticated-app/authenticated-app'));

export const Routes = () => {
  const { pathname, search } = useLocation();

  const isLoggedIn = useAppSelector((store) => store.auth.isLoggedIn);

  const customContextData: RxntLaunchDarklyCustomContextData = {
    // Enable feature by targeting token for post-release testing
    enrollmentToken:
      !isLoggedIn && pathname == appConstants.browserRoutes.patientEnrollment
        ? search.replace('?', '')
        : undefined,
  };
  return (
    <RxntLaunchDarklyProvider customContextData={customContextData}>
      {isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </RxntLaunchDarklyProvider>
  );
};
